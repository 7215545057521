.gridcontainer {
  display: grid;
  grid-template-columns: 15% 85%;
  height: 90%;
}

.contentcontainer {
  height: 100%;
  overflow-y: auto;
}

.nabar {
  height: 10%;
}

.head_str {
  font-size: 30px;
}

.help_card_bg {
  background: #2c192c;
}

.help_card_bg:hover {
  box-shadow: 5px 5px 5px 5px #bba0a0;
  zoom: 150%;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.navigation_outer {
  overflow-y: auto;
  overflow-x: hidden;
  height: 90%;
}

.navigation_outer::-webkit-scrollbar {
  width: 1em !important;
  height: 0.3em;
}

.navigation_outer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.navigation_outer::-webkit-scrollbar-thumb {
  background: #2a274a !important;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%),
    inset -2px -2px 2px rgb(0 0 0 / 25%);
}

.custom-container {
  display: grid;
  grid-template-columns: 15% 85%;
  height: 90%;
}

.content-panel {
  height: 65vh !important;
}

body {
  overflow: hidden;
}

.activestepcss {
  z-index: 2;
  border-left: 10px solid #2a274a !important;
  color: #2a274a !important;
  background-color: white !important;
}

.img_outer {
  box-shadow: 0px 0px 5px 5px grey;
  width: 81%;
}

#help-header .btn-close {
  position: absolute;
  right: 4%;
  top: 10%;
  border-radius: 30%;
  opacity: 1;
  z-index: 3000;
  box-shadow: 0 0 5px 5px #68687a;
}

.full-screen-modal .modal-content {
  background: #13132017;
}

.full_screen_btn {
  position: absolute;
  z-index: 30;
  top: 2%;
  right: 2%;
  background-color: white;
  padding: 7px;
  border-radius: 21px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 50%);
}

.full_screen_outer {
  position: relative;
}

.full_screen_outer img {
  width: 100%;
  height: auto;
}
