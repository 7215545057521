.modal-content{
    align-items: center;
}

.modalTitle {
    color: #08087a;
    font-size: x-large;
}
.modalText {
    padding-left: 2rem;
    padding-bottom: 1rem;
    color: #000000;
}